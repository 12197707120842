<template>
  <div
    class="modal fade"
    :id="`modalColumnDesign`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalColumnDesignLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body">
          <template v-if="viewableFields.length > 0">
            <div class="bg-light border p-2 w-100 mb-1">
              <div class="row exclude-divs">
                <div class="col-md-9">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="me-2">
                      <label for="">
                        {{
                          $t(
                            "BaseModelFields.Filter",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :autocomplete="$isAutoComplete"
                        @keyup="filterField()"
                        v-model="query"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="btn btn-success w-100"
                    @click="allAppendToVisibleField"
                  >
                    <i class="bi bi-eye bi-lg"></i>
                    {{
                      $t(
                        "ViewFilters.AllAppendVisibleFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="card mt-2 mb-2"
              :class="{
                'overflow-auto max-height-300':
                  visibleFields.length > 0 || hiddenFields.length > 0,
              }"
            >
              <div class="card-body pt-0 pb-2 px-3">
                <div class="row mt-3">
                  <div
                    class="widget-filter-field col-md-4 col-xs-12 col-sm-6"
                    v-for="item in viewableFields"
                    :key="item.key"
                  >
                    <div class="card mb-2">
                      <div class="card-body p-1 bg-warning bg-gradient">
                        <div
                          class="d-flex align-items-center justify-content-between h-30"
                        >
                          <div
                            class="text-wrap text-break text-transform-capitalize filter-field-name fs-15"
                          >
                            {{ item.value }}
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-xs btn-success btn-no-custom-style"
                              @click="visibleFieldAdd(item)"
                            >
                              <i class="bi bi-eye bi-lg"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-xs btn-success ms-1 btn-no-custom-style"
                              @click="hiddenFieldAdd(item)"
                            >
                              <i class="bi bi-eye-slash bi-lg"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="visibleFields.length > 0">
            <div
              class="bg-light border p-2 w-100 mb-1"
              :class="{
                'mt-2': viewableFields.length > 0,
              }"
            >
              <div class="row exclude-divs">
                <div class="col-md-9">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="me-2">
                      <label for="">
                        {{
                          $t(
                            "ViewFilters.TableWidth",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        @change="clientWidthOnChange"
                        class="form-control"
                        min="0"
                        :value="clientWidth"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    @click="allRemoveVisibleFields"
                    class="btn btn-danger w-100"
                  >
                    <i class="bi bi-trash bi-lg"></i>
                    {{
                      $t(
                        "ViewFilters.AllRemoveVisibleFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div
            class="mt-2 overflow-auto"
            :style="{
              'max-width': String.format('{0}px', windowSize - 46),
            }"
            v-if="visibleFields.length > 0 || hiddenFields.length > 0"
          >
            <table
              :style="{
                width: String.format(
                  '{0}{1}',
                  clientWidth > 0 ? clientWidth : '100',
                  clientWidth > 0 ? 'px' : '%'
                ),
              }"
              class="table table-bordered table-selected-fields mb-0"
              v-if="visibleFields.length > 0"
            >
              <thead>
                <tr class="bg-warning bg-gradient">
                  <td
                    :colspan="visibleFields.length"
                    class="text-center fw-bold bg-gradient"
                  >
                    <i class="bi bi-eye"></i>
                    {{
                      $t(
                        "ViewFilters.VisibleFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th
                    v-for="item in visibleFields"
                    :key="item.key"
                    :data-id="item.key"
                    :style="{ width: item.width + 'px' }"
                  >
                    <div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn btn-xxs btn-danger btn-no-custom-style"
                          @click="visibleFieldRemove(item)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-xxs btn-success btn-no-custom-style"
                          @click="appendToHiddenField(item)"
                        >
                          <i class="bi bi-eye-slash"></i>
                        </button>
                      </div>
                      <div class="text-center">
                        <p class="mt-1 mb-1 text-trasform-capitalize">
                          {{ item.value }}
                        </p>
                        <div class="btn-group text-center" role="group">
                          <input
                            type="radio"
                            class="btn-check"
                            @change="fieldAlignChange(item, 'left')"
                            :name="String.format('align_{0}', item.key)"
                            :id="String.format('align_left_{0}', item.key)"
                            value="left"
                            :checked="item.align == 'left'"
                          />
                          <label
                            class="btn btn-warning text-dark btn-custom-size btn-xxs btn-no-custom-style d-flex align-items-center justify-content-center radius-top-left-bottom-left-4"
                            :for="String.format('align_left_{0}', item.key)"
                            ><i class="bi bi-text-left bi-lg"></i
                          ></label>
                          <input
                            type="radio"
                            class="btn-check"
                            @change="fieldAlignChange(item, 'center')"
                            :name="String.format('align_{0}', item.key)"
                            :id="String.format('align_center_{0}', item.key)"
                            value="center"
                            :checked="item.align == 'center'"
                          />
                          <label
                            class="btn btn-warning text-dark btn-custom-size btn-xxs btn-no-custom-style d-flex align-items-center justify-content-center"
                            :for="String.format('align_center_{0}', item.key)"
                            ><i class="bi bi-text-center bi-lg"></i
                          ></label>
                          <input
                            type="radio"
                            class="btn-check"
                            @change="fieldAlignChange(item, 'right')"
                            :name="String.format('align_{0}', item.key)"
                            :id="String.format('align_right_{0}', item.key)"
                            value="right"
                            :checked="item.align == 'right'"
                          />
                          <label
                            class="btn btn-warning text-dark btn-xxs btn-custom-size d-flex radius-top-right-bottom-right-4 align-items-center justify-content-center btn-no-custom-style"
                            :for="String.format('align_right_{0}', item.key)"
                            ><i class="bi bi-text-right bi-lg"></i
                          ></label>
                        </div>
                      </div>
                      <div class="text-center mt-1">
                        <div class="btn-group text-center" role="group">
                          <label
                            :for="String.format('isDisabled_{0}', item.key)"
                            class="mb-1 text-capitalize float-none"
                          >
                            {{
                              $t(
                                "Components.PageDesignerModal.IsDisabled",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </label>
                          <div
                            class="form-check d-flex justify-content-center w-100"
                          >
                            <input
                              class="form-check-input disabled-check-btn btn-custom-size bd-1"
                              type="checkbox"
                              @change="fieldDisabledChange($event, item)"
                              value=""
                              :id="String.format('isDisabled_{0}', item.key)"
                              :checked="item.disabled"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-1">
                        <div class="btn-group text-center" role="group">
                          <label
                            :for="String.format('isRequired_{0}', item.key)"
                            class="mb-1 text-capitalize float-none"
                          >
                            {{
                              $t(
                                "Components.PageDesignerModal.IsRequired",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </label>
                          <div
                            class="form-check d-flex justify-content-center w-100"
                          >
                            <input
                              class="form-check-input required-check-btn btn-custom-size bd-1"
                              type="checkbox"
                              @change="fieldRequiredChange($event, item)"
                              value=""
                              :id="String.format('isRequired_{0}', item.key)"
                              :checked="item.required"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-1">
                        <div class="btn-group text-center" role="group">
                          <label
                            :for="String.format('isRequired_{0}', item.key)"
                            class="mb-1 text-capitalize float-none"
                          >
                            {{
                              $t(
                                "ViewFilters.ColumnWidth",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }} </label
                          ><br />
                          <span
                            class="badge text-capitalize"
                            :class="{
                              'badge-primary': item.width > 0,
                              'badge-warning': item.width == 0,
                              'badge-danger': item.width < 0,
                            }"
                          >
                            {{ item.width == 0 ? "auto" : item.width + "px" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="mt-2" v-if="hiddenFields.length > 0">
            <table class="table table-bordered table-hidden-fields mb-0 mt-3">
              <thead>
                <tr class="bg-warning bg-gradient">
                  <td
                    :colspan="hiddenFields.length"
                    class="text-center fw-bold bg-gradient"
                  >
                    <i class="bi bi-eye-slash"></i>
                    {{
                      $t(
                        "ViewFilters.HiddenFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <th
                    v-for="item in hiddenFields"
                    :key="item.key"
                    :data-id="item.key"
                  >
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn btn-xxs btn-danger btn-no-custom-style"
                        @click="hiddenFieldRemove(item)"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-xxs btn-success btn-no-custom-style"
                        @click="appendToVisibleField(item)"
                      >
                        <i class="bi bi-eye"></i>
                      </button>
                    </div>
                    <div class="text-center">
                      <p class="mt-1 mb-1 text-trasform-capitalize">
                        {{ item.value }}
                      </p>
                    </div>
                    <div class="text-center mt-1">
                      <div class="btn-group text-center" role="group">
                        <label
                          :for="String.format('isDisabled_{0}', item.key)"
                          class="mb-1 text-capitalize float-none"
                        >
                          {{
                            $t(
                              "Components.PageDesignerModal.IsDisabled",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </label>
                        <div
                          class="form-check d-flex justify-content-center w-100"
                        >
                          <input
                            class="form-check-input disabled-check-btn btn-custom-size bd-1"
                            type="checkbox"
                            @change="fieldDisabledChange($event, item)"
                            value=""
                            :id="String.format('isDisabled_{0}', item.key)"
                            :checked="item.disabled"
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            @click="setIndexesAndVisibleFieldsSort"
          >
            <i class="bi bi-save me-1"></i>
            {{
              $t(
                "Components.PageDesignerModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "@/assets/css/fa-all.css";
require("jquery-ui/ui/widgets/resizable.js");
require("jquery-ui/ui/widgets/sortable.js");
require("jquery-ui/themes/base/all.css");

export default {
  name: "ColumnDesigner",
  props: {
    recordDetail: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clientWidth: 0,
      windowSize: window.innerWidth,
      visibleFields: [],
      hiddenFields: [],
      query: "",
      viewableFields: [],
    };
  },
  watch: {
    recordDetail() {
      this.modelCreating(0, this.recordDetail.visibleFields);
      this.modelCreating(1, this.recordDetail.hiddenFields);
      this.clientWidth = this.recordDetail.clientWidth;
    },
    fields() {
      this.viewableFields = this.fields;
    },
  },
  methods: {
    setIndexesAndVisibleFieldsSort() {
      this.visibleFields.forEach((visibleField) => {
        var item = $(
          String.format(
            ".table-selected-fields thead tr:last th[data-id='{0}']",
            visibleField.key
          )
        );
        if (item) {
          visibleField.index = item.index();
        }
      });
      this.visibleFields = this.visibleFields.sort((a, b) => a.index - b.index);
    },
    appendToHiddenField(item) {
      this.visibleFields = this.visibleFields.filter(function (f) {
        return f.key !== item.key;
      });

      this.hiddenFields.push({
        key: item.key,
        value: item.value,
        width: 0,
        widthLocked: false,
        align: "left",
        disabled: item.disabled,
        required: false,
      });
    },
    appendToVisibleField(item) {
      this.hiddenFields = this.hiddenFields.filter(function (f) {
        return f.key !== item.key;
      });

      this.visibleFields.push({
        key: item.key,
        value: item.value,
        width: 0,
        widthLocked: false,
        align: "left",
        disabled: item.disabled,
        required: false,
      });

      setTimeout(() => {
        this.inits();
      }, 1000);
    },
    fieldAlignChange(item, value) {
      item.align = value;
    },
    fieldDisabledChange(e, item) {
      item.disabled = $(e.target).is(":checked");
    },
    fieldRequiredChange(e, item) {
      item.required = $(e.target).is(":checked");
    },
    allAppendToVisibleField() {
      this.viewableFields.forEach((field) => {
        this.visibleFieldAdd(field, false);
      });

      this.clientWidthOnChange({ target: { value: this.clientWidth } });

      setTimeout(() => {
        this.inits();
      }, 1000);
    },
    allRemoveVisibleFields() {
      this.visibleFields.forEach((field) => {
        this.visibleFieldRemove(field);
      });
      this.clientWidth = 0;
    },
    clientWidthOnChange(e) {
      this.clientWidth = e.target.value;
      if (String.isNullOrWhiteSpace(this.clientWidth)) {
        this.clientWidth = 0;
      }

      if (this.clientWidth == 0) {
        this.visibleFields.forEach((field) => {
          field.width = "0";
          field.widthLocked = false;
        });
      } else {
        var width = this.clientWidth / this.visibleFields.length;
        this.visibleFields.forEach((field) => {
          field.width = parseInt(width);
          field.widthLocked = false;
        });
      }
    },
    inits() {
      var resizableCols = $(".table-selected-fields thead tr:last th"),
        sortableCols = $(".table-selected-fields thead tr:last"),
        self = this;
      resizableCols.resizable({
        handles: "e",
        resize: function (e, ui) {
          var recordDetail = self.visibleFields.find(
              (f) => f.key == ui.element.data("id")
            ),
            currentColumnWidth = parseInt(ui.size.width);

          if (recordDetail) {
            if (currentColumnWidth > self.clientWidth && self.clientWidth > 0) {
              recordDetail.width = self.clientWidth;
            } else {
              recordDetail.width = parseInt(currentColumnWidth);
            }
            recordDetail.widthLocked = true;
          }
        },
        stop: function (event, ui) {
          if (self.clientWidth > 0) {
            var editedColumns = self.visibleFields
                .filter((f) => f.widthLocked)
                .map((m) => m.width),
              notEditedColumns = self.visibleFields.filter(
                (f) => !f.widthLocked
              );
            if (editedColumns.length > 0) {
              var value = Number.sum(editedColumns),
                valueToOtherColumns = self.clientWidth - value,
                avg = valueToOtherColumns / notEditedColumns.length;

              notEditedColumns.forEach((element) => {
                element.width = parseInt(avg);
              });
            }
          }
        },
      });
      sortableCols
        .sortable({
          axis: "x",
          cursor: "grab",
          placeholder: "ui-state-highlight",
          helper: "clone",
          stop: function (event, ui) {
            ui.item
              .first()
              .find("input[type=radio]:first")
              .prop("checked", true);
          },
        })
        .disableSelection();
    },
    visibleRowsInits() {
      var self = this;
      var timer = setInterval(function () {
        var resizableCols = $(".table-selected-fields thead tr:last th"),
          sortableCols = $(".table-selected-fields thead tr:last");

        if (resizableCols.length > 0 && sortableCols.length > 0) {
          self.inits();
          clearInterval(timer);
        }
      }, 1000);
    },
    filterField() {
      var value = this.query.toLowerCase();
      $(".widget-filter-field").filter(function () {
        var parentDiv = $(this);
        parentDiv.toggle(
          $(this)
            .find(".filter-field-name")
            .text()
            .trim()
            .toLowerCase()
            .indexOf(value) > -1
        );
      });
    },
    modelCreating(type, items) {
      if (!items) return;
      items.forEach((item) => {
        if (type == 0) {
          this.visibleFields.push({
            key: item.publicId,
            value: item.fieldModel.name,
            width: item.width,
            widthLocked: item.widthLocked,
            align: item.align,
            disabled: item.disabled,
            required: item.required,
          });
        } else {
          this.hiddenFields.push({
            key: item.publicId,
            value: item.fieldModel.name,
            width: 0,
            widthLocked: false,
            align: "left",
            disabled: item.disabled,
            required: false,
          });
        }
      });

      if (type == 0 && items.length > 0) {
        this.visibleRowsInits();
      }
    },
    visibleFieldAdd(field, userClicked = true) {
      this.visibleFields.push({
        key: field.key,
        value: field.value,
        width: 0,
        widthLocked: false,
        disabled: false,
        required: false,
        align: "left",
      });

      this.viewableFields = this.viewableFields.filter(function (f) {
        return f.key !== field.key;
      });

      if (userClicked) {
        setTimeout(() => {
          this.inits();
        }, 1000);
      }

      var parent = this.$parent,
        obj = { key: field.key, value: field.value };
      if (parent) {
        parent.aggregateFields.push(obj);
      }
    },
    visibleFieldRemove(field) {
      this.viewableFields.push({
        key: field.key,
        value: field.value,
      });

      this.visibleFields = this.visibleFields.filter(function (f) {
        return f.key !== field.key;
      });

      var width = field.width,
        value = parseInt(width / this.visibleFields.length);

      this.visibleFields.forEach((field) => {
        field.width = field.width + value;
      });

      var parent = this.$parent;
      if (!parent) return;

      parent.selectedAggregateFields = parent.selectedAggregateFields.filter(
        function (f) {
          return f.field !== field.key;
        }
      );
      parent.aggregateFields = parent.aggregateFields.filter(function (f) {
        return f.field !== field.key;
      });
    },
    hiddenFieldAdd(field) {
      this.hiddenFields.push({
        key: field.key,
        value: field.value,
        disabled: false,
        required: false,
        align: "left",
        width: 0,
        widthLocked: false,
      });

      this.viewableFields = this.viewableFields.filter(function (f) {
        return f.key !== field.key;
      });

      var parent = this.$parent,
        obj = { key: field.key, value: field.value };
      if (parent) {
        parent.aggregateFields.push(obj);
      }
    },
    hiddenFieldRemove(field) {
      this.viewableFields.push({
        key: field.key,
        value: field.value,
      });

      this.hiddenFields = this.hiddenFields.filter(function (f) {
        return f.key !== field.key;
      });

      var parent = this.$parent;
      if (!parent) return;

      parent.selectedAggregateFields = parent.selectedAggregateFields.filter(
        function (f) {
          return f.field !== field.key;
        }
      );
      parent.aggregateFields = parent.aggregateFields.filter(function (f) {
        return f.field !== field.key;
      });
    },
  },
};
</script>
